module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["sk"],"defaultLanguage":"sk","siteUrl":"https://suggestics.onrender.com","redirect":false,"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true},{"matchPath":"/:lang?/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Suggestics","short_name":"Suggestics","start_url":"/","background_color":"#F5F5F5","theme_color":"#2c40ed","display":"standalone","icon":"/opt/render/project/src/suggestics/src/assets/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"06c41ba80c4586f376d104d88a626fd9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
