import {fonts} from 'uikit'
import {transparentize} from 'polished'
import texture from './assets/texture.png'


const BLUE = '#2c40ed'
const BLACK = '#000000'
const LIGHT_GRAY = '#EEEEEE'
const RICH_BLACK = '#050505'
const WHITE = '#FFFFFF'
const GRAY = '#BEBEBE'
const DARK_BLUE = '#00012c'
const RED = '#FF0000'

const theme = {
  colors: {
    primary: BLUE,
    secondary: DARK_BLUE,
    text: RICH_BLACK,
    text2: WHITE,
    text3: BLACK,
    background: LIGHT_GRAY,
    background2: WHITE,
    background3: DARK_BLUE,
    background4: DARK_BLUE,
    aux: GRAY,
    negative: RED,
  },
  texture: {
    src: texture,
    cover: true,
  },
  fonts: {
    family: {
      primary: fonts.satoshiRegular.name,
      secondary: fonts.satoshiBold.name,
    },
  },
  overlays: {
    background: transparentize(0.5, BLACK),
    backgroundNoBlur: transparentize(0.3, BLACK),
    backgroundNoBlurCookieBar: transparentize(0.125, BLACK),
  },
  border: {
    default: '1px solid',
    radius: {
      xs: '0.25rem',
      sm: '0.5rem',
      md: '0.5rem',
      lg: '1.25rem',
      xl: '1.75rem',
    },
  },
}

export default theme
